<script>
import Layout from "../../layouts/main";
import subscription from './subscription.vue';
import plan from './price.vue';
import Multiselect from 'vue-multiselect';

import { FETCH_MERCHANTS_LIST } from "@/state/actions.type";

export default {
    components: {
        Layout,
        subscription,
        plan,
        Multiselect
    },
    data() {
        return {
            activeTab: "Subscription",
            tabs: ['Subscription','Plan'],
            merchant_uuids: [],
            showFilters: false
        }
    },
    watch: {
        '$route.query.tab'() {
            if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
                this.activeTab = this.$route.query.tab;
                this.merchant_uuids = [];
            }
        },
    },
    computed: {
        merchantListDataFromStore() {
            return this.$store.state.masterMerchant.content;
        },
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
    },
    created() {
        if(this.isMasterMerchant){
            this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
                pageIndex: 1,
                pagesize: 10,
                filters: { source: "default" },
            });
        }
        if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
            this.activeTab = this.$route.query.tab;
        }
    },
    methods: {
        tabClicked(tab) {
            this.activeTab = tab;
            this.$router.push({name: 'Subscription', query: {tab: tab}});
        }
    }
}
</script>

<template>
    <Layout>
        <div class="menu-content">
            <div class="tab-content-section">
                <b-btn variant="primary" v-for="(tab, index) in tabs" :key="index" @click="tabClicked(tab)" class="tab-button" :class="{'tab-active': activeTab === tab}">{{ tab }}</b-btn>
            </div>

            <div class="d-flex justify-content-end" style="gap: 10px;">
                <multiselect
                    v-if="isMasterMerchant"
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    placeholder="Search Merchants"
                    track-by="email_id"
                    label="email_id"
                    style="width: 300px;"
                    :searchable="true"
                    :multiple="true"
                    :taggable="true"
                ></multiselect>
                <b-btn @click="showFilters = !showFilters" class="filter-button" variant="white">
                    <img style="padding-right: 10px;" src="@/assets/images/filter.svg" alt="Filter">{{ $t('common.filters') }}
                </b-btn>
            </div>
        </div>
        <subscription :merchants="merchant_uuids" :showFilters="showFilters" v-if="activeTab === 'Subscription'" />
        <plan :merchants="merchant_uuids" :showFilters="showFilters" v-if="activeTab === 'Plan'" />
    </Layout>
</template>

<style scoped>

.filter-button {
    border-radius: 12px;
    padding: 12px 16px;
    height: 44px;
    border: 1px solid #DCDAD5;
}
</style>