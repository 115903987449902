<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
// import FilterBtn from "../../../components/filter-btn.vue";
import SubscriptionForm from './subscription-form.vue';
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import * as moment from "moment";
import Multiselect from 'vue-multiselect';
import { FETCH_SUBSCRIPTION_LIST, DELETE_SUBSCRIPTION } from "@/state/actions.type";
import SearchBy from '../../../components/search-by.vue';
import PerPage from '../../../components/per-page.vue';

export default {
  components: {
    // Layout,
    // PageHeader,
    // FilterBtn,
    SubscriptionForm,
    DatePicker,
    Multiselect,
    SearchBy,
    PerPage
  },

  props: {
    merchants: {
      type: Array,
      default: () => {
        return []
      },
    },
    showFilters: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      // mor_filter: "",
      isFetchingSubscriptionData: false,
      isAddSubscriptionModalOpen: false,
      merchant_uuids: [],
      fields: [
        { key: "created_at", sortable: false, label: "subscription.created_date" },
        { key: "plan_name", sortable: false, label: "Plan Name" },
        { key: "recurrance_count", sortable: false, label: "Recurrence Count" },
        { key: "amount", sortable: false, label: "Amount" },
        { key: "quantity", sortable: false, label: "subscription.quantity" },
        { key: "status", sortable: false, label: "Status" },
        { key: "customer_name", sortable: false, label: "Customer Name" },
        { key: "customer_email_address", sortable: false, label: "Email Id" },
        { key: "customer_phone_number", sortable: false, label: "Mobile No" },
        { key: "order_ref", sortable: false, label: "Sub Id" },
        { key: "price_order_ref", sortable: false, label: "subscription.Plan_ID" },
        { key: "link", sortable: false, label: "Sub Link" },
        { key: "customer_email_address", sortable: false, label: "subscription.email_Id" },
        { key: "customer_phone_number", sortable: false, label: "subscription.mobile_No" },
        { key: "status", sortable: false, label: "subscription.status" },
        { key: "show_details", sortable: false, label: '', stickyColumn: true },
      ],
      filterOptions: [
        "Plan ID",
        "Quantity"
      ],
      searchBy: {
        key: "Plan ID",
        value: ""
      },
      // currentSelectedFilter: "",
      isFilterApplied: false,
      fromDate: "",
      toDate: "",
      filters: {
        status: []
      }

    };
  },
  filters: {
    datetime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm a");
    },
    date(value) {
        return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
  },
  computed: {
    title() {
      return this.$t("menuitems.subscriptions.text");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.subscriptions.text"),
          active: true
        }
      ];
    },

    rows() {
      return this.$store.state.subscription.total_elements;
    },

    subscriptionData() {
      return this.$store.state.subscription.content;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  watch: {
    perPage() {
      this.fetchSubscriptionList();
    },
    'fromDate': function(newFromDate) {
      if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
        const toDate = new Date(newFromDate);
        console.log(toDate)
        toDate.setHours(23, 59, 59); // Set time to the end of the day
        this.toDate = toDate;
      }
      else{
        this.toDate = "";
      }
    },
    showFilters() {
      this.$root.$emit('bv::toggle::collapse', 'subscription-filter-collapse')
    },
    merchants(value) {
      this.merchant_uuids = value;
      this.fetchSubscriptionList();
    },
  },

  created() {
    this.fetchSubscriptionList();
  },

  methods: {
    resetAdvanceFilters() {
      this.filters = {
        created_at: ""
      };
      this.fetchSubscriptionList();
      this.isFilterApplied = false;
    },

    fetchSubscriptionList() {
      let filters = {
        status: this.filters.status
      };
      if(this.searchBy.value && this.searchBy.key) {
        if(this.searchBy.key === "Plan ID") {
          filters.price_order_ref = this.searchBy.value;
        } else {
          filters[this.searchBy.key.toLowerCase()] = this.searchBy.value;
        }
      }

      let fromDate = "2006-01-02T09:34:05.000Z";
      if(this.fromDate) {
        fromDate = moment(this.fromDate).format("YYYY-MM-DDTHH:mm:ssZ")
      }

      let toDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
      if(this.toDate) {
        let newDate = new Date(this.toDate);
        newDate.setHours(23, 59, 59, 59)
        toDate = moment(newDate).format("YYYY-MM-DDTHH:mm:ssZ")
      }

      let payload = {
        "page": this.currentPage,
        "to": toDate,
        "from": fromDate,
        "pagesize": this.perPage,
        "filters": filters
      }

      if(this.isMasterMerchant) {
        payload.merchant_keys = this.merchant_uuids.map(element => element.key);
      }

      this.$store.dispatch(`subscription/${FETCH_SUBSCRIPTION_LIST}`, payload)
    },

    closeCreateSubscriptionModal() {
      this.isAddSubscriptionModalOpen = false;
      this.fetchSubscriptionList();
    },

    deleteSubscription(item) {

      Swal.fire({
        title: "Are you sure you want to delete?",
        text:"Order Ref - "+ item.order_ref,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`subscription/${DELETE_SUBSCRIPTION}`, item.order_ref)
            .then((response) => {
              console.log(response);

              Swal.fire("Deleted!", "Subscription has been deleted.", "success");

              setTimeout(() => {
                this.fetchSubscriptionList();
              }, 500);
            })
            .catch((error) => {
              console.log("error", error);
              Swal.fire("Deleted!", "Failed To delete Subscription.", "error");
            });
        }
      });
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "Subscription Link copied to clipboard.",
        closeOnClick: true,
      });
    },
    disabledAfterTodayAndAfterTo(date) {
      if(this.toDate) {
        return date > new Date() || date > new Date(this.toDate)
      }
      return date > new Date();
    },
    disabledAfterTodayAndBeforeFrom(date) {
      if(this.fromDate) {
        return date > new Date() || date < new Date(this.fromDate)
      }
      return date > new Date();
    },
    // openSubscriptionDetailsPage(orderRef) {
    //   this.$router.push();
    // },
    
    openSubscriptionDetailsPage(pageRef, submerchant_key) {
      this.$router.push({path: '/subscriptions/details', query: { pageRef: pageRef, key: this.isMasterMerchant ? submerchant_key : '' }});
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>



<template>
  <!-- <Layout> -->
    <!-- <PageHeader :title="title" :items="items" /> -->
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-btn v-b-toggle.subscription-filter-collapse style="position: absolute;right: 22px; bottom: 36px;" class="filter-button" variant="white">
          <img style="padding-right: 10px;" src="@/assets/images/filter.svg" alt="Filter">{{ $t('common.filters') }}
        </b-btn>
      </div>
    </div>

    <!-- <div class="row my-4">
      <div class="col-sm-12 col-xl-4 d-flex align-items-baseline"> -->
        <!-- <div @click="resetAdvanceFilters"> -->
          <!-- <FilterBtn
            :isActive="isFilterApplied"
          /> -->
        <!-- </div> -->
        <!-- <div id="tickets-table_length" class="dataTables_length ml-3">
          <label class="d-inline-flex align-items-center">
            <span class="text-nowrap">{{ $t('common.show') }}</span>&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;<span class="text-nowrap">{{ $t('common.entries') }}</span>
          </label>
        </div> -->
      <!-- </div>
    </div> -->

    <div class="row">
      <div class="col-sm-12 col-md-12">
        <b-collapse id="subscription-filter-collapse">
          <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-4 col-xl-4">
                <date-picker
                  type="date"
                  placeholder="From Date"
                  format="DD-MM-YYYY"
                  v-model="fromDate"
                  name="startdate"
                  class="custom-datepicker"
                  prefix-class="xmx"
                  :disabled-date="disabledAfterTodayAndAfterTo"
                ></date-picker>
              </div>
              <div class="col-sm-12 col-md-4 col-xl-4">
                <date-picker
                  type="date"
                  placeholder="To Date"
                  format="DD-MM-YYYY"
                  v-model="toDate"
                  name="enddate"
                  class="custom-datepicker"
                  prefix-class="xmx"
                  :disabled-date="disabledAfterTodayAndBeforeFrom"
                ></date-picker>
              </div>
              <div class="col-sm-12 col-md-4 col-xl-4">
                <multiselect
                  v-model="filters.status"
                  :placeholder="$t('common.search_transaction_status')"
                  :searchable="true"
                  :options="['Created', 'Authenticated', 'Active', 'Pending', 'Cancelled', 'Expired', 'Completed']"
                  :multiple="true"
                  class="sm-multiselect"
                ></multiselect>
              </div>
              <!-- <div class="col-sm-12 col-md-6 col-xl-3">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <b-button
                      variant="primary"
                      style="height: 35px"
                      class="btn btn-block btn-sm"
                      @click="fetchSubscriptionList"
                      >{{ $t("button.apply") }}</b-button
                    >
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <b-button
                      variant="light"
                      style="height: 35px"
                      class="btn btn-block btn-sm"
                      @click="resetAdvanceFilters"
                      v-b-toggle.subscription-filter-collapse
                      >{{ $t("button.clear") }}</b-button
                    >
                  </div>
                </div>
              </div> -->
            </div>
            <div class="d-flex justify-content-between mt-3">
              <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
              <div>
                <b-btn variant="primary" v-b-toggle.subscription-filter-collapse  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                <b-btn variant="primary" class="btn-padding" @click="fetchSubscriptionList">Apply</b-btn>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-3 mt-3">
      <search-by v-model="searchBy" :options="filterOptions" @search-by-clicked="fetchSubscriptionList" />

              <!-- Custom Search -->
      <!-- <div class="col-sm-12 col-xl-8 d-flex justify-content-end">
        <label class="mr-2" style="width: 100px"> {{ $t('common.search_by') }}</label>
        <b-form-select
          class="w-50"
          v-model="currentSelectedFilter"
          size="sm"
          :options="filterOptions"
        ></b-form-select
        >&nbsp;
        <b-input-group class="w-75">
          <b-form-input
            type="search"
            v-model="mor_filter"
            @input="fetchSubscriptionList"
            @keyup.enter="fetchSubscriptionList"
            class="form-control form-control-sm ml-2"
            :placeholder="$t('subscription.enter_ref')"
          ></b-form-input>
          <b-input-group-append>
            <span>
              <button
                class="btn btn-primary btn-block btn-sm"
                @click="fetchSubscriptionList"
              >
                {{ $t("views.payments.transactions.search") }}
              </button>
            </span>
          </b-input-group-append>
        </b-input-group>
      </div> -->

      <!-- Custom Search End-->

      <router-link
        :to="'/subscriptions/create-subscriptions'"
      >
        <b-btn
            v-if="!isMasterMerchant"
            variant="primary"
            size="sm"
            class="float-right"
            style="padding: 10px 16px;font-weight: 400;line-height: 20px;"
          >
            <span style="padding-right: 10px;">+</span>{{ $t('subscription.create_subscription') }}
          </b-btn>
      </router-link>
    </div>

    <div class="">
      <b-table
        class="table-centered text-left table-custom"
        :items="subscriptionData"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :small="true"
        sticky-header="50vh"
        show-empty
        :empty-text="$t('common.no-records')"
        busy.sync="true"
        hover
      >
      <template v-slot:head()="data">
          <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
            {{ $t(data.label) }}
          </span>
        </template>
        <template #cell(order_ref)="row">
          <router-link :to="`/subscriptions/details?pageRef=${row.value}&key=${row.item.chaipaykey}`"><span class="text-chai">{{ row.value }}</span></router-link>
        </template>
        <template v-slot:cell(status)="row">
          <div
            class="badge font-size-12"
            style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
            :class="{
              'badge-soft-warning': `${row.value}` === 'Created' || `${row.value}` === 'Pending',
              'badge-soft-authenticated': `${row.value}` === 'Authenticated',
              'badge-soft-success': `${row.value}` === 'Active' || `${row.value}` === 'Completed',
              'badge-soft-danger': `${row.value}` === 'Cancelled' || `${row.value}` === 'Expired',
            }"
          >
            {{ row.value }}
          </div>
        </template>
        <template #cell(quantity)="row">
          {{ row.value || '-' }}
        </template>
        <template #cell(recurrance_count)="row">
          {{ row.value || '-' }}
        </template>
        <template #cell(amount)="row">
          {{ row.value || '-' }}
        </template>
        <template v-slot:cell(link)="row">
          <!-- <div class="d-flex justify-content-center align-items-center">
            <a class="d-flex" target="_black" v-b-tooltip.hover.right :title="row.value" :href="row.value">
              <p class="text-truncate mb-0" style="width: 6rem;">{{ row.value }}</p>
              <i style="font-size: 15px;" class="mdi mdi-open-in-new"></i>
            </a>
            <span class="ml-4">
              <i
                v-show="row.value"
                v-clipboard:copy="row.value"
                v-clipboard:success="onCopySuccess"
                v-b-tooltip.hover.right
                title="copy"
                class="mdi mdi-content-copy btn btn-lg p-0"
              ></i>
            </span>
          </div> -->

          <div class="d-flex justify-content-center align-items-center">
                    <a class="d-flex" target="_black" v-b-tooltip.hover.right :title="row.value" :href="row.value">
                      <p class="text-truncate mb-0" style="width: 200px; color:#FC6B2D; font-weight: 500;">{{ row.value }}</p>
                      <!-- <i style="font-size: 15px;" class="mdi mdi-open-in-new"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" style="padding-left: 3px;" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g opacity="0.7" clip-path="url(#clip0_1540_47447)">
                        <path d="M9.75 8.25L15.9 2.1M16.5 5.1V1.5H12.9M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1540_47447">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <span class="ml-3" 
                        v-show="row.value"
                        v-clipboard:copy="row.value"
                        v-clipboard:success="onCopySuccess"
                        v-b-tooltip.hover.right
                        :title="$t('common.copy')"
                        style="cursor: pointer; width: 40px;height: 40px;background: rgba(245, 244, 243, 1);border-radius: 8px;position: relative;">
                      <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g opacity="0.7" clip-path="url(#clip0_1540_47398)">
                          <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1540_47398">
                            <rect width="18" height="18" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>



        </template>
        <template v-slot:cell(period)="row">
          <span>
            {{ cycles[row.value] }}
          </span>
        </template>
        <template #cell(created_at)="row">
          
            <p class="table-date">{{ row.value | date }}</p>
            <p class="table-time">{{ row.value | time }}</p>
        </template>
        <template #cell(show_details)="row">
          <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
              <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                      <template v-slot:button-content>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
                      </svg>
                      </template>
                    <b-dropdown-item-button @click="openSubscriptionDetailsPage(row.item.order_ref, row.item.chaipaykey)">Subscription Details</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="!['Completed', 'Cancelled', 'Expired'].includes(row.item.status)" @click="deleteSubscription(row.item)">{{ $t("button.delete") }}</b-dropdown-item-button>
              </b-dropdown>
          </div>
        </template>
      </b-table>
      <Spinner v-if="isFetchingSubscriptionData === true"></Spinner>
      <div class="d-flex justify-content-between mb-3">
          <!-- pagination -->
          <per-page
            v-model="perPage"
            :currentPage="currentPage"
            :totalRows="rows"
            :tableRowsCount="subscriptionData.length"
            @input="fetchSubscriptionList"
          />
          <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="fetchSubscriptionList" ></b-pagination>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <b-pagination
              v-model="currentPage"
              :current-page="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @input="fetchSubscriptionList"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div> -->

    <div
      v-if="!isMasterMerchant"
      class="right-bar"
      :class="{
        active: isAddSubscriptionModalOpen,
      }"
    >
      <div data-simplebar class="h-100" style="overflow-x: auto">
        <div class="rightbar-title">
          <div class="right-top-section">
            <div class="card-body-custom">
              <i
                class="mdi mdi-close noti-icon float-right cursor-pointer"
                style="
                  position: relative;
                  z-index: 1;
                  right: 15px;
                  top: 15px;
                  font-size: 20px;
                "
                @click="closeCreateSubscriptionModal"
              ></i>
              <div class="media-body media-body-custom">
                <p
                  class="d-inline-block pl-4"
                  style="font-size: 20px; margin-top: 30px; margin-bottom: 30px"
                >
                  {{ $t('subscription.create_subscription') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <SubscriptionForm v-if="isAddSubscriptionModalOpen" />
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- Right bar overlay-->
    <div
      v-if="!isMasterMerchant"
      @click="closeCreateSubscriptionModal"
      class="rightbar-overlay"
      :class="{
        active: isAddSubscriptionModalOpen,
      }"
    ></div>
  </div>
  <!-- </Layout> -->
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep .xmx-datepicker{
  width: 100%;
}

::v-deep .custom-datepicker .xmx-input{
  height: 35px !important;
  padding-top: 4px;
  min-height: unset;
}

::v-deep .input-group-addon{
   padding: 10px 10px;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    background-color: #ecf0f1;
    border: 1px solid #dce4ec;
    border-left-color: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  /* background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%; */
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 600px;
  right: -600px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
::v-deep .form-control,v-deep .form-group {
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
::v-deep .custom-datepicker .xmx-input{
  height: 44px !important;
  padding-top: 4px;
  min-height: unset;
  border: 1px solid #DCDAD5;
  box-shadow: none;
  border: 1px solid #DCDAD5;
  border-radius: 12px;
}
::v-deep  .show_details ul{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    transform: translate3d(-232px, 0px, 0px) !important;
    width: 220px;

}
::v-deep .show_details ul button{
    padding: 12px 25px;
    border-radius: 0px;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
.filter-button {
    border-radius: 12px;
    padding: 12px 16px;
    height: 44px;
    border: 1px solid #DCDAD5;
}
</style>
